import styled from 'styled-components';

export const Container = styled.div`
  background: #00aaad;
  color: #fff;

  a {
    color: #fff;
  }

  padding: var(--vertical-half) var(--horizontal) calc(var(--vertical-half) / 2);

  h3 {
    color: #333;
    font-size: 20px;
  }

  span {
    color: #444;
  }
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    margin-right: 2rem;
  }
`;
export const Information = styled.div`
  margin: 2rem 0;

  div.map {
    display: none;
  }

  @media (min-width: 800px) {
    display: flex;
    flex-direction: row;

    div.map {
      display: unset;
    }

    > div + div {
      margin-left: calc(2em + 2vw);
    }
  }
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  svg {
    margin-right: 20px;
  }

  div.phone-group {
    display: flex;
    flex-direction: column;
  }
`;

export const FooterBar = styled.div`
  display: flex;
  flex-direction: row;
  color: #fff;
  background-color: #00999f;
  justify-content: space-between;
  min-height: 80px;
  padding: calc(var(--vertical-half) / 2) var(--horizontal);

  a {
    color: #fff;
  }

  > p {
    font-size: 0.8em;
  }

  p:nth-of-type(2) {
    text-align: right;
  }
`;

export const Spacer = styled.div`
  height: 80px;
  width: 100%;

  @media (min-width: 720px) {
    display: none;
  }
`;
