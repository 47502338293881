import { MdKeyboardArrowRight } from 'react-icons/md';
import elaineImg from '../../assets/images/elaine-1.jpg';
import {
  Container,
  Title,
  ImageWrapper,
  QualificationsList,
  Qualification,
  TextSection,
} from './styles';

export const About = ({ id }: { id: string }) => {
  return (
    <Container id={id} title="About">
      <Title>
        <h4>prazer, eu sou a</h4>
        <h1>Elaine Varotto</h1>
      </Title>
      <QualificationsList>
        <Qualification>
          <MdKeyboardArrowRight size={30} />
          <span>
            Psicóloga Clínica formada pelo Centro Universitário Metropolitano de
            Maringá
          </span>
        </Qualification>

        <Qualification>
          <MdKeyboardArrowRight size={30} />
          <span>Especialista em Psicoterapia Psicanalítica</span>
        </Qualification>

        <Qualification>
          <MdKeyboardArrowRight size={30} />
          <span>
            Pós-graduanda em Psicoterapia Psicanalítica Contemporânea pela EPPM
            (Escola de Psicoterapia Psicanalítica de Maringá - PR)
          </span>
        </Qualification>
      </QualificationsList>

      <ImageWrapper>
        <img alt="Elaine Cristina Varotto" src={elaineImg} />
      </ImageWrapper>

      <TextSection>
        <span>
          Contribuo para <strong>promover mudanças e transformações</strong>,
          orientar nas reflexões e nas diversas formas de enfrentamento das
          dificuldades com foco na melhoria da qualidade e no desempenho dos
          vários setores da vida.
        </span>
      </TextSection>
    </Container>
  );
};
