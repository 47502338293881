import React from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import snippetImg from '@/assets/images/card_snippet.jpg';

import GlobalStyle from '@/styles/global';
import { useInView } from 'react-intersection-observer';

import { BottomMenu, Header } from '@/components';

import {
  About,
  Hero,
  Psychotherapy,
  Methods,
  Services,
  Contact,
} from '@/sections';

const Home: React.FC<PageProps> = () => {
  const [ref, inView] = useInView({ threshold: 0.9 });

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://psielainevarotto.com.br" />
        <meta
          property="og:title"
          content="Elaine Cristina Varotto | Psicóloga CRP 08/31049 | Mundo Novo, MS"
        />
        <meta property="og:image" content={snippetImg} />
        <meta property="og:url" content="https://psielainevarotto.com.br/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Psicóloga em Mundo Novo, MS. Atendimento a adolescentes, adultos, idosos e casais com sessões presenciais ou online."
        />
        <meta
          name="description"
          content="Psicóloga em Mundo Novo, MS. Atendimento a adolescentes, adultos, idosos e casais com sessões presenciais ou online."
        />
        <meta
          name="keywords"
          content="psicologa, elaine varotto, psicoterapeuta, psicóloga em mundo novo ms, psicoterapia, terapeuta, psicanálise"
        />
        <title>
          Elaine Cristina Varotto | Psic&oacute;loga CRP 08/31049 | Mundo Novo,
          MS
        </title>
      </Helmet>
      <Header isInView={inView} />

      <Hero heroRef={ref} />
      <About id="about" />
      <Psychotherapy id="psychotherapy" />
      <Methods id="methods" />
      <Services id="services" />
      <Contact id="contact" />
      <BottomMenu />

      <GlobalStyle />
    </main>
  );
};

export default Home;
