import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Footer } from '@/components';

import inPerson from '@/assets/images/in_person.jpg';
import { Container, SocialStrip, SocialButtons, Social } from './styles';

export const Contact = ({ id }: { id: string }) => {
  return (
    <Container id="contact" title="Contact">
      <SocialStrip>
        <div>
          <p>
            Me acompanhe nas redes sociais e fique por dentro de novidades, além
            de conteúdo original, e doses de inspiração e informação a respeito
            do meu trabalho e da psicologia.
          </p>
        </div>
        <SocialButtons>
          <Social href="https://www.facebook.com/ElaineVarottoPsicologa/">
            <div className="content">
              <FaFacebookF size="1.8em" />
              <span>
                curta no <br />
                <strong>Facebook</strong>
              </span>
            </div>
          </Social>
          <Social href="https://www.instagram.com/psicologaelainevarotto/">
            <div className="content">
              <FaInstagram size="1.8em" />
              <span>
                siga no <br />
                <strong>Instagram</strong>
              </span>
            </div>
          </Social>
        </SocialButtons>
      </SocialStrip>

      <Footer />
    </Container>
  );
};
