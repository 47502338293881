import { FaWhatsapp } from 'react-icons/fa';
import online from '@/assets/icons/online.svg';
import chatBubble from '@/assets/icons/chat-bubble.svg';
import target from '@/assets/icons/target.svg';
import file from '@/assets/icons/file.svg';

import {
  Container,
  ServicesItems,
  Item,
  Icon,
  HeroContainer,
  Hero,
  HeroContentsMobile,
  WebBackground,
} from './styles';

export const Services = ({ id }: { id: string }) => {
  return (
    <>
      <Container id={id} title="Services">
        <h1>Serviços</h1>
        <ServicesItems>
          <Item>
            <Icon>
              <img alt="Atendimento online" src={online} />
              <img alt="Atendimento presencial" src={chatBubble} />
            </Icon>
            <p>
              psicoterapia para adolescentes, adultos, idosos e casais com
              atendimento <strong>presencial</strong> ou <strong>online</strong>
              .
            </p>
          </Item>
          <Item>
            <Icon>
              <img alt="Terapia breve" src={target} />
            </Icon>
            <p>
              psicoterapia breve com especificidade e ênfase no trabalho com um
              foco e limite de tempo, definido logo no início do tratamento ou
              após algumas sessões.
            </p>
          </Item>
          <Item>
            <Icon>
              <img alt="Avaliações psicológicas" src={file} />
            </Icon>
            <p>
              avaliações psicológicas e laudos técnicos por meio de análise
              pessoal determinando características psicológicas e tendências de
              personalidade
            </p>
          </Item>
        </ServicesItems>
      </Container>

      <HeroContainer>
        <Hero>
          <HeroContentsMobile>
            <p>
              Aprender a se colocar em primeiro lugar não é egoísmo nem orgulho,
              e sim <strong>amor próprio!</strong>
            </p>
            <p>
              Meu objetivo é te auxiliar na construção de pensamentos e ações em
              busca do seu bem-estar, sempre direcionando para compreensão de si
              mesmo.{' '}
            </p>
          </HeroContentsMobile>
          <a href="tel:5567992760909">
            <FaWhatsapp size={24} />
            Agende uma consulta
          </a>
        </Hero>
        <WebBackground />
      </HeroContainer>
    </>
  );
};

export default Services;
