import styled from 'styled-components';

interface ISidebarProps {
  isMobile: boolean;
}

export const Container = styled.div`
  position: relative;
  z-index: 21;
`;

export const Sidebar = styled.div<ISidebarProps>`
  color: #fff;
  position: fixed;
  right: 0;
  background: #00999f;
  width: 40%;
  min-width: 170px;
  height: ${props => (props.isMobile ? 110 : 100)}vh;

  z-index: 40;
  transition: all 0.5s;
  transform: translateX(100%);

  &.open {
    transform: translateX(0%);
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20vh;
    padding: 0 3vw;

    button {
      color: #fff;
      background: none;
      border: none;
      text-transform: uppercase;
    }

    button:first-of-type {
      margin-bottom: 3rem;
    }

    li {
      text-transform: uppercase;
      cursor: pointer;
      margin-bottom: calc(2vh);
      padding: 0.2em;

      a.active {
        font-weight: bold;
      }
    }

    button:last-of-type {
      display: flex;
      flex-direction: row;
      margin-top: 15vh;
      align-items: center;

      svg {
        margin-left: auto;
        margin-right: 5px;
      }
    }
  }
`;

export const Nav = styled.header`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.4rem var(--horizontal-half);
  z-index: 20;
  transition: background 250ms ease-in;

  @media (min-width: 720px) {
    &.scrolled {
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    }

    background-color: transparent;
  }

  a,
  span {
    color: #333333;
  }
`;

export const NavLinks = styled.ul`
  display: none;

  @media (min-width: 720px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    align-items: center;
    list-style: none;
    justify-content: space-between;
    max-width: 80%;

    transition: opacity 0.1s ease;

    li a {
      font-size: 1em;
      text-decoration: none;
      text-transform: uppercase;
    }

    a {
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      position: relative;
      transition: color 0.2s ease-in-out;
    }

    a::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      background-color: currentColor;
      transform: scaleX(0);
      transition: transform 0.2s ease-in-out;
    }

    a:hover::after {
      transform: scaleX(1);
    }

    a.active {
      font-weight: bold;
    }

    li + li {
      margin-left: 3vw;
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  width: 20%;
  max-width: 200px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  gap: unset;
  width: unset;

  img:first-of-type {
    width: 60%;
    min-width: 3rem;
  }

  img:nth-of-type(2) {
    transition: opacity 0.3s ease-in-out;
    width: 80%;
    opacity: 0;
  }

  span {
    display: none;
  }

  @media (min-width: 720px) {
    min-width: 9rem;
    gap: 1rem;

    img:first-of-type {
      width: 40%;
      min-width: 3rem;
      margin-bottom: 10px;
    }
    img:nth-of-type(2) {
      width: 85%;

      opacity: 1;
      visibility: visible;
    }

    span {
      margin-top: 5px;
      font-size: 0.8em;
      text-align: center;
      transition: all 0.5s ease-in-out;
    }
  }
`;

export const Hamburger = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  margin-left: auto;
  margin-top: -0.5rem;
  justify-content: center;

  > div {
    width: 30px;
    height: 3px;
    background: #333333;
  }

  div:first-of-type {
    width: 25px;
    margin-left: auto;
  }

  div + div {
    margin-top: 3px;
  }

  @media (min-width: 720px) {
    display: none;
  }
`;
