import { useState, useEffect } from 'react';

export default function useDeviceDetect(): Record<string, unknown> {
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

    let device = 'desktop';

    if (/android/i.test(userAgent)) {
      device = 'android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      device = 'ios';
    }

    setDeviceType(device);
  }, []);

  return { deviceType };
}
