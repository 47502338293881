import styled from 'styled-components';

export const Container = styled.div`
  margin-top: var(--vertical-half);
  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 40% auto;
    grid-template-rows: 25% auto auto;
    grid-template-areas:
      'photo title'
      'photo qualifications'
      'photo text';

    margin: 0 var(--horizontal) var(--vertical);
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: var(--vertical-half) var(--horizontal);
  background-color: #8b64aa;
  color: #fff;

  padding-bottom: 3em;
  grid-area: title;

  h1 {
    font-family: 'Montserrat', sans-serif;
  }

  h4 {
    font-size: 1.3em;
    opacity: 0.7;
  }

  @media (min-width: 900px) {
    padding: 0 var(--horizontal-half) calc(var(--vertical-half) / 2);
    background-color: unset;
    color: #8b64aa;
  }
`;

export const QualificationsList = styled.ul`
  padding: 0 var(--horizontal) var(--vertical-half);
  color: #fff;
  z-index: -1;
  display: flex;
  flex-direction: column;
  height: fit-content;

  background-color: #8b64aa;

  grid-area: qualifications;
  overflow-x: hidden;

  @media (min-width: 900px) {
    background-color: #f2eee7;
    width: 105vw;
    padding: var(--vertical-half) 14vw var(--vertical-half) 52vw;
    margin-left: -46vw;

    svg,
    span {
      color: #333;
    }
  }
`;

export const Qualification = styled.li`
  display: flex;
  align-items: flex-start;

  svg {
    margin-left: -10px;
    margin-right: 10px;
  }

  span {
    width: 100%;
  }

  & + & {
    margin-top: 1rem;
  }
`;

export const ImageWrapper = styled.div`
  background: linear-gradient(180deg, #f2eee7 50%, #fff 50%);
  grid-area: photo;

  img {
    width: 100%;
    padding: var(--horizontal);
  }

  @media (min-width: 900px) {
    display: flex;
    align-items: center;
    background: unset;
    img {
      padding: var(--horizontal--half);
      box-shadow: -10px -10px 0px 0px rgba(139, 100, 170, 1);
    }
  }
`;

export const TextSection = styled.div`
  background-color: #f2eee7;
  padding: var(--vertical-half) var(--horizontal-half);
  grid-area: text;

  @media (min-width: 900px) {
    background-color: transparent;
    width: 90%;
    padding-top: 3em;
  }
`;
