import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Section = styled.div`
  background: #f7f5f1;
  padding: var(--vertical) var(--horizontal);

  > p + p {
    margin-top: 3rem;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  flex-shrink: 0;

  h1 {
    margin-right: 3vw;
  }

  img {
    width: clamp(45px, 10vw, 100px);
  }
`;
export const Iceberg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    padding: var(--vertical-half) var(--horizontal);
    padding-left: var(--horizontal);
  }

  img {
    padding-bottom: var(--vertical-half);
  }

  @media (max-width: 380px) {
    img {
      width: 85%;
    }
  }

  > div:last-child {
    color: #fff;
    background-color: #000;
  }

  @media (min-width: 1120px) {
    margin: var(--vertical) 0;
    justify-content: center;
    flex-direction: row;

    > * {
      flex: 1 1 0;
    }

    > div:first-of-type {
      padding: unset;
      margin-left: var(--horizontal);
    }

    > div:nth-of-type(2) {
      padding: calc(var(--horizontal) / 6);
      margin-right: var(--horizontal);
    }

    img {
      padding: unset;
      margin: 0 calc(var(--horizontal-half) / 2);
    }
  }

  @media (max-width: 1120px) {
    > div {
      padding-right: var(--horizontal);
    }
  }
`;
