import { Quote } from '@/components';

import titleIcon from '@/assets/icons/psychotherapy.svg';
import collaborativeIcon from '@/assets/icons/collaborative.svg';
import investigateIcon from '@/assets/icons/investigate.svg';
import comprehendIcon from '@/assets/icons/comprehend.svg';

import {
  Container,
  Section,
  Title,
  DisordersContainer,
  Disorder,
  ProcessesContainer,
  Process,
} from './styles';

export const Psychotherapy = ({ id }: { id: string }) => {
  return (
    <Container id={id} title="Psicoterapia">
      <Section>
        <Title>
          <h1>a psicoterapia</h1>
          <img alt="Psicoterapia" src={titleIcon} />
        </Title>
        <p>
          consiste em uma análise pessoal com o intuito de tratar questões
          relacionadas à <strong>saúde mental</strong> e problemas psicológicos
          assim como auxiliar na <strong>integração harmônica</strong> das
          grandes mudanças que ocorrem ao longo de nossas vidas.
        </p>
        <p>
          É um processo mediado por <strong>profissional capacitado</strong> e
          que visa alcançar autoconhecimento de modo a aumentar seu{' '}
          <strong>bem-estar</strong> e <strong>equilíbrio psicológico</strong>{' '}
          diante das situações da vida.
        </p>

        <p>
          Alguns dos motivos e razões para a procura de psicoterapia incluem,
          porém não se limitam a:
        </p>
      </Section>

      <DisordersContainer>
        <Disorder>
          <h2>Depressão</h2>
          <p>
            sentimentos de tristeza, perda do interesse em atividades que em
            momentos anteriores lhe traziam prazer e alegria.
          </p>
        </Disorder>
        <Disorder>
          <h2>Ansiedade</h2>
          <p>
            tensão, apreensão, desconforto e/ou imaginação imprevisível que
            sempre se refere a possibilidades ruins que possam acontecer no
            futuro, um grande mal-estar e desconforto.
          </p>
        </Disorder>
        <Disorder>
          <h2>Relacionamentos</h2>
          <p>
            auxílio na resolução de possíveis desencontros como problemas de
            ordem familiar que podem influenciar o casal, discussões que não
            conseguem identificar o motivo, distanciamento e falha na
            comunicação.
          </p>
        </Disorder>
        <Disorder>
          <h2>
            Orientação <br />
            profissional
          </h2>
          <p>
            tem como objetivo guiar estudantes e profissionais a identificarem
            seu perfil, junto ao auxílio na tomada de decisões de forma a
            alcançar seus propósitos e vocação em carreira acadêmica e/ou
            profissional.
          </p>
        </Disorder>
      </DisordersContainer>
      <ProcessesContainer>
        <Process>
          <img
            alt="A psicoterapia é um processo colaborativo"
            src={collaborativeIcon}
          />
          <p>
            A psicoterapia é um <strong>processo colaborativo</strong> entre
            analista e paciente continuamente identificando problemas, definindo
            objetivos e focando na melhoria da qualidade e desempenho nos
            diversos vários setores da vida.
          </p>
        </Process>
        <Process>
          <img
            alt="O tratamento busca identificar a origem de comportamentos e pensamentos"
            src={investigateIcon}
          />
          <p>
            O tratamento psicológico busca identificar a{' '}
            <strong>origem de comportamentos e pensamentos</strong>, auxiliando
            cada indivíduo a lidar com seus conflitos internos ao passo que visa
            o auto conhecimento, auto controle e crescimento pessoal.
          </p>
        </Process>
        <Process>
          <img
            alt="O analista tem como propósito auxiliar o paciente"
            src={comprehendIcon}
          />
          <p>
            O analista tem como propósito <strong>auxiliar o paciente</strong> a
            compreender situações desafiadoras e conflituosas de forma mais
            acessível a consciência, guiado pela linha da psicologia analítica
            mais conhecida como psicanálise.
          </p>
        </Process>
      </ProcessesContainer>

      <Quote
        author="Jacob Bronowski"
        authorOccupation="matemático e historiador"
        backgroundColor="#00aaad"
        textColor="#fff"
      >
        “A consciência é o nosso modo de análise do mundo externo na forma de{' '}
        <strong>objetos</strong> e <strong>ações</strong>
        .”
      </Quote>
    </Container>
  );
};
