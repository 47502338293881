import styled from 'styled-components';

interface QuoteProps {
  backgroundColor: string;
  textColor: string;
}

export const Container = styled.div<QuoteProps>`
  display: flex;
  flex-direction: column;
  padding: var(--vertical) calc(var(--horizontal) * 1.5);
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};

  span {
    font-family: 'Lora', serif;
    font-size: 1.5em;
    position: relative;
    padding-left: 2em;
    border-left: calc(0.1em + 4px) solid ${props => props.textColor};
  }

  strong.highlight:first-of-type {
    color: #8b64aa;
  }

  strong.highlight:nth-of-type(2) {
    color: #00aaad;
  }

  @media (min-width: 900px) {
    padding: var(--vertical) var(--horizontal);
  }
`;
export const AuthorInfo = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.3em;

  p.author {
    font-weight: 700;
  }
`;
