import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-scroll';
import { Quote } from '@/components';

import useWindowDimensions from '@/hooks/useWindowDimensions';
import { Container, Contents, ButtonGroup } from './styles';

interface HeroProps {
  heroRef: any;
}

export const Hero = ({ heroRef }: HeroProps) => {
  const { innerHeight } = useWindowDimensions();
  return (
    <>
      <Container windowHeight={innerHeight} ref={heroRef}>
        <Contents>
          <p>
            <span>
              A psicoterapia é um espaço somente seu: é{' '}
              <strong>liberdade de expressão</strong> para seus desejos e
              conflitos em busca do <strong>autoconhecimento</strong> e{' '}
              <strong>melhor qualidade de vida</strong>.
            </span>
          </p>
          <ButtonGroup>
            <a href="tel:5567992760909">
              <FaWhatsapp size={20} /> Agendar uma consulta
            </a>

            <Link to="psychotherapy" spy smooth offset={-200}>
              Saiba mais
            </Link>
          </ButtonGroup>
        </Contents>
      </Container>
      <Quote author="Carl Jung" backgroundColor="#fff" textColor="#333333">
        &quot;Conheça todas as teorias, domine todas as técnicas, mas ao tocar
        uma <strong className="highlight">alma humana</strong>, seja apenas
        outra <strong className="highlight">alma humana</strong>
        .”
      </Quote>
    </>
  );
};
