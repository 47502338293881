import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  display: flex;
  background-color: #fff;

  > div:first-child {
    display: none;

    @media (min-width: 720px) {
      height: 40vh;
      margin: calc(1rem + 2vw) 0;
      display: flex;
      align-items: center;
      padding: 0 var(--horizontal);
      justify-content: center;

      > div:first-child {
        margin-right: 4em;
      }
    }
  }

  img {
    width: 100%;
  }
`;

export const SocialStrip = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    width: 50%;
    padding: var(--padding-half);
  }
`;

export const SocialButtons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const Social = styled.a`
  flex-grow: 1;
  width: 50%;
  max-width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  transition: 250ms all ease-in-out;

  border-radius: 1rem;

  & + & {
    margin-left: calc(0.5rem + 2vw);
  }

  &:first-of-type {
    background-color: #6352c9;

    &:hover {
      background-color: ${darken(0.2, '#6352c9')};
    }
  }

  &:nth-of-type(2) {
    background-color: #ce3eae;

    &:hover {
      background-color: ${darken(0.2, '#ce3eae')};
    }
  }

  span {
    margin-top: 0.5em;
    line-height: 1.2;
  }

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  div.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;
