import { ReactNode } from 'react';

import { Container, AuthorInfo } from './styles';

interface QuoteProps {
  children: ReactNode;
  backgroundColor: string;
  textColor: string;
  author: string;
  authorOccupation?: string;
}

export const Quote = ({
  author,
  authorOccupation,
  children,
  ...rest
}: QuoteProps) => {
  return (
    <Container {...rest}>
      <span>{children}</span>
      <AuthorInfo>
        <p className="author">{author} </p>
        {authorOccupation && <p>{authorOccupation}</p>}
      </AuthorInfo>
    </Container>
  );
};
