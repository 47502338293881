import styled from 'styled-components';
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  background: #fff;
  width: 100%;
  height: 80px;
  justify-content: space-around;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const Item = styled.a`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  cursor: pointer;

  &:first-of-type {
    background-color: #fafafa;
  }
  &:nth-of-type(2) {
    background-color: #eee;
  }
  &:nth-of-type(3) {
    background-color: #e3e3e3;
  }
`;

export const Facebook = styled(FaFacebookF)`
  color: #4267b2;
`;
export const Instagram = styled(FaInstagram)`
  color: #c13584;
`;
export const WhatsApp = styled(FaWhatsapp)`
  color: #35b294;
`;
