import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

    --horizontal: 1.5em;
    --horizontal-half: 0.75em;

    --vertical: 3em;
    --vertical-half: 1.5em;

    @media (min-width: 400px) {
      --horizontal: calc(5vw + 1rem);
    --horizontal-half: calc(3vw + 1rem);

    --vertical: 6em;
    --vertical-half: 3em;
    }

    @media (min-width: 1000px) {
      --vertical: 4em;
      --vertical-half: 4em;

      --horizontal: 12vw;
      --horizontal-half: 6vw;
    }

    @media (min-width: 1400px) {
      --vertical: 8em;
      --vertical-half: 4em;

      --horizontal: 14vw;
      --horizontal-half: 7vw;
    }

  }

  body {
    background-color: transparent;
    overflow-x:hidden;
    color: #333333;

    font-size: 13px;
    font-family: 'Barlow', sans-serif;
  }

  main {

    -webkit-font-smoothing: antialised;

    line-height: 1.5;


    @media (min-width: 480px) {
      font-size: 14px;
    }

    @media (min-width: 720px) {
      font-size: 17px;
    }

    @media (min-width: 1200px) {
      font-size: 20px;
    }

    @media (min-width: 1920px) {
      font-size: 21px;
    }

  }

  p, span {
    @media (min-width: 720px) {
      line-height: 1.3;
    }

    @media (min-width: 1400px) {
      line-height: 1.5;
    }

  }



  input, button {
    font-family: 'Barlow', sans-serif;

    line-height: 0;
  }

  h1, h2, h3, h4, h5, h6  {
    font-family: 'Lora', serif;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 1;
  }

  strong {
    font-weight: 700;
  }

  h1 {
    font-size: 3em;

   @media (min-width: 720px) {
    font-size: calc(1.4em + 2vw);
   }
  }

  h2 {
    font-size: 2em;

    @media (min-width: 600px) {
      font-size: calc(1em + 2vw);
    }

    @media (min-width: 1200px) {
      font-size: calc(1em + 2vw);
    }

  }

  button {
    cursor: pointer;
    background: #f30d47;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

`;
