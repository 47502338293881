import { useEffect, useState } from 'react';
import { GoogleMap, Marker, GoogleMapProps } from '@react-google-maps/api';

const LocationMap = (props: GoogleMapProps) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (!mounted) return null;
  return (
    <GoogleMap
      zoom={15}
      center={{ lat: -23.9353908, lng: -54.2838322 }}
      {...props}
    >
      <Marker
        title="Clinica de Psicologia Elaine Varotto"
        position={{ lat: -23.9353908, lng: -54.2838322 }}
      />
    </GoogleMap>
  );
};

export default LocationMap;
