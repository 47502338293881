import useDeviceDetect from '@/hooks/useDeviceDetect';
import { Container, Item, Facebook, Instagram, WhatsApp } from './styles';

export const BottomMenu = () => {
  const { deviceType } = useDeviceDetect();

  return (
    <Container>
      <Item href="https://wa.me/5567992760909">
        <WhatsApp size={24} />
      </Item>

      <Item href="https://www.instagram.com/psicologaelainevarotto/">
        <Instagram size={24} />
      </Item>
      {deviceType === 'android' && (
        <Item href="fb://page/111755970558427">
          <Facebook size={24} />
        </Item>
      )}
      {deviceType === 'ios' && (
        <Item href="fb://profile/111755970558427">
          <Facebook size={24} />
        </Item>
      )}
      {deviceType === 'desktop' && (
        <Item href="https://www.facebook.com/ElaineVarottoPsicologa/">
          <Facebook size={24} />
        </Item>
      )}
    </Container>
  );
};
