/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useCallback, useEffect, useRef, MouseEvent } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

import { MdClear, MdKeyboardArrowUp } from 'react-icons/md';
import { isMobile } from 'react-device-detect';
import ShortLogo from '../../assets/logo/symbol.svg';
import TextLogo from '../../assets/logo/text.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import {
  Container,
  Nav,
  LogoContainer,
  NavLinks,
  Hamburger,
  Sidebar,
} from './styles';

interface HeaderProps {
  isInView: boolean;
}

interface LinkItem {
  title: string;
  to: string;
  offset: number;
}

const links: LinkItem[] = [
  { title: 'Quem sou', to: 'about', offset: -150 },
  { title: 'Psicoterapia', to: 'psychotherapy', offset: -150 },
  { title: 'Abordagem', to: 'methods', offset: -150 },
  { title: 'Serviços', to: 'services', offset: -150 },
  { title: 'Contato', to: 'contact', offset: 80 },
];

export const Header = ({ isInView }: HeaderProps) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const sideBarRef = useRef<HTMLDivElement>(null);
  const { innerWidth } = useWindowDimensions();

  const scrollToTop = () => {
    setSidebarIsOpen(false);
    scroll.scrollToTop();
  };

  const handleCloseMenu = useCallback(() => {
    setSidebarIsOpen(false);
  }, []);

  useEffect(() => {
    const handleClick = (e: any) => {
      const node = sideBarRef.current;

      if (sidebarIsOpen) {
        if (!node?.contains(e.currentTarget)) {
          setSidebarIsOpen(!sidebarIsOpen);
        }
      }
    };
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [sidebarIsOpen]);

  useEffect(() => {
    if (innerWidth > 720) {
      setSidebarIsOpen(false);
    }
  }, [innerWidth]);

  return (
    <Container>
      <Nav className={!isInView ? 'scrolled' : ''}>
        <LogoContainer onClick={scrollToTop}>
          <img src={ShortLogo} alt="Elaine Varotto" />
          <img src={TextLogo} alt="Elaine Varotto" />
          <span>PSICÓLOGA</span>
        </LogoContainer>

        <NavLinks>
          {links.map(link => (
            <li key={link.title}>
              <Link to={link.to} spy smooth offset={link.offset}>
                {link.title}
              </Link>
            </li>
          ))}
        </NavLinks>
        <Hamburger onClick={() => setSidebarIsOpen(true)}>
          <div />
          <div />
          <div />
        </Hamburger>
      </Nav>
      <Sidebar
        isMobile={isMobile}
        ref={sideBarRef}
        className={`sidebar ${sidebarIsOpen && innerWidth < 720 ? 'open' : ''}`}
      >
        <ul>
          <button className="close" type="button" onClick={handleCloseMenu}>
            <MdClear size={20} />
          </button>

          {links.map(link => (
            <li key={link.title}>
              <Link
                to={link.to}
                spy
                smooth
                offset={isMobile ? link.offset + 80 : link.offset}
                onClick={() => setSidebarIsOpen(false)}
              >
                {link.title}
              </Link>
            </li>
          ))}

          <button type="button" className="to-top" onClick={scrollToTop}>
            <MdKeyboardArrowUp size={20} />
            Voltar ao topo
          </button>
        </ul>
      </Sidebar>
    </Container>
  );
};
