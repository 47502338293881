import styled from 'styled-components';
import elaineImg from '../../assets/images/elaine-2.jpg';

export const Container = styled.div`
  padding: var(--vertical) var(--horizontal-half);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h1 {
    text-transform: lowercase;
  }
`;

export const ServicesItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 80%;
  margin-top: 3rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    height: 50px;
  }

  p {
    margin-top: 1rem;
    text-align: center;
  }

  @media (min-width: 1024px) {
    flex-basis: 100%;
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row;

  img + img {
    margin-left: 1rem;
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 828px) {
    div:nth-of-type(2) {
      display: none;
    }
  }
`;

export const Hero = styled.div`
  min-height: 70vh;
  background-image: url(${elaineImg});
  background-size: cover;
  background-position-x: 45%;
  background-position-y: 20%;
  background-repeat: no-repeat;
  position: relative;
  justify-content: center;
  z-index: 5;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8) 3%,
      rgba(255, 255, 255, 0.3) 45%,
      rgba(255, 255, 255, 0) 100%
    );
    top: 0;
    left: 0;
    z-index: -1;
  }

  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 0 var(--horizontal-half) 0 var(--horizontal);

  @media (min-width: 828px) {
    justify-content: center;
    width: 50%;
    background-image: none;
    background-color: #f7f5f1;
  }

  a {
    display: flex;
    padding: 0.5em 1.3em;
    border-radius: 2rem;
    font-weight: bold;
    font-size: 1.1em;
    align-items: center;
    background-color: #f30d47;
    color: #fff;
    border: 0;
    width: fit-content;

    svg {
      margin-right: 10px;
    }
  }
`;

export const HeroContentsMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  p {
    margin-bottom: calc(1rem + 2vw);
    font-size: 1em;
  }

  @media (min-width: 828px) {
    width: 100%;
    margin-top: unset;
  }
`;

export const WebBackground = styled.div`
  background-image: url(${elaineImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 70%;
  width: 50%;
  height: 70vh;
`;
