import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;

  .mouse-icon {
    width: 25px;
    height: 45px;
    border: 2px solid #333333;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    text-align: center;
  }

  span {
    height: 6px;
    margin: 2px auto 0;
    display: block;
    width: 3px;
    background-color: #333333;
    border-radius: 50%;
    animation: 1.6s ease infinite wheel-up-down;
  }

  @keyframes wheel-up-down {
    0% {
      margin-top: 2px;
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      margin-top: 20px;
      opacity: 0;
    }
  }
`;
