import sectionIcon from '@/assets/icons/psychanalysis.svg';
import iceberg from '@/assets/illustrations/iceberg.svg';

import { Container, Section, Title, Iceberg } from './styles';

export const Methods = ({ id }: { id: string }) => {
  return (
    <Container id={id} title="Methods">
      <Section>
        <Title>
          <h1>
            a abordagem <br />
            psicanalítica
          </h1>
          <img alt="Abordagem psicanalítica" src={sectionIcon} />
        </Title>
        <p>
          é fundamentada por meio da compreensão dos problemas e circunstâncias
          que nos afetam, dedicando-se a entender em essência como funcionam a
          mente humana e os processos psíquicos.
        </p>
        <p>
          Por meio da psicanálise, passa-se a conhecer melhor a própria mente
          assim como a identificação da razão por trás dos sentimentos e emoções
          considerando fatores além dos conflitos em questão de um indivíduo,
          isto é, percebendo-se e sendo visto como um todo, um conjunto de
          circunstâncias que desencadeiam os pensamentos e comportamentos.
        </p>
        <p>
          Na psicologia, a <strong>psique</strong> é um termo que define de
          forma abrangente a personalidade, englobando os pensamentos,
          sentimentos e comportamentos – representa em essência a totalidade da
          mente. Segundo Freud, ela se assemelha visualmente a um{' '}
          <strong>iceberg</strong>.
        </p>
      </Section>
      <Iceberg>
        <div>
          <p>
            A parte superficial e instantaneamente vísivel a olho nu representa
            a <strong>consciência</strong>, os pensamentos e atividades mentais
            que possuímos controle: é a percepção da relação entre si e um
            ambiente, o estado de estar ciente de algo ou alguma coisa.
          </p>
        </div>
        <img
          alt="Modelo de consciência proposto por Sigmund Freud"
          src={iceberg}
        />
        <div>
          <p>
            Em contraste, o <strong>inconsciente</strong> é representado pela
            área submersa do iceberg e representa todos os impulsos, conflitos
            pessoais e questionamentos morais ocultos e recusados pelo filtro do
            ego.
          </p>
        </div>
      </Iceberg>
      <Section>
        <p>
          Quando memórias, idéias e lembranças são dolorosas ou inapropriadas
          para a mente consciente suportar, elas são reprimidas por impulsos
          instintivos e armazenados no inconsciente, que as conduz em silêncio
          para que não sejam acessíveis a consciência.
        </p>
        <p>
          A <strong>psicoterapia psicanalítica</strong> é uma poderosa
          ferramenta que busca <strong>investigar os conflitos</strong> e{' '}
          <strong>aliviar a tensão</strong> psíquica por meio de um
          apronfundamento no inconsciente, permitindo e aprendendo a lidar com
          as memórias e sentimentos anteriormente reprimidos.
        </p>
      </Section>
    </Container>
  );
};
