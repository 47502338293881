import styled from 'styled-components';

import heroImg from '@/assets/images/hero-bg-2.jpg';

interface ContainerProps {
  windowHeight: number;
}

export const Container = styled.div<ContainerProps>`
  height: 80vh;
  display: flex;
  flex-direction: column;
  background-image: url(${heroImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 20%;
  padding: var(--vertical) var(--horizontal-half) 0;

  @media (max-width: 1024px) {
    background-position: 12%;
  }

  @media (min-width: 800px) {
    align-items: flex-end;
    > div:first-of-type {
      margin-left: 40%;
    }
  }
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: auto;
  z-index: 3;

  > p {
    > span {
      line-height: 135%;
      line-height: 1.6;
      font-size: 2rem;

      display: inline;

      @media (max-width: 800px) {
        font-size: 1.8rem;
        padding: 0.12em;
        box-shadow: 0.3em 0 0 #00aaad, -0.3em 0 0 #00aaad;

        background: #00aaad;
        color: #ffffff;
      }
    }
  }

  strong {
    font-weight: bold;
  }

  @media (min-width: 800px) {
    margin-top: 35vh;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;

  margin-top: 2em;

  a {
    display: flex;
    margin-bottom: 0.5rem;

    padding: 0.7em 1.3em;
    border-radius: 2em;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    flex-shrink: 0;

    svg {
      margin-right: 10px;
    }
  }

  a:first-of-type {
    background-color: #f30d47;
    color: #fff;
    margin-right: 1em;
  }

  a:nth-of-type(2) {
    color: #00aaad;
    cursor: pointer;
    box-shadow: inset 0px 0px 0px 2px #00aaad;
  }
`;
