import styled from 'styled-components';

import depression from '../../assets/illustrations/depression.svg';
import anxiety from '../../assets/illustrations/anxiety.svg';
import relationship from '../../assets/illustrations/relationship.svg';
import professional from '../../assets/illustrations/professional.svg';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  margin: var(--vertical) var(--horizontal);

  p + p {
    margin-top: 2rem;
  }

  p:nth-of-type(3) {
    font-style: italic;
  }

  @media (min-width: 900px) {
    margin: var(--vertical-half) var(--horizontal) var(--vertical);
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 2rem;

  h1 {
    margin-right: 3vw;
  }

  img {
    width: clamp(45px, 8vw, 100px);
  }
`;

export const DisordersContainer = styled.div`
  width: 100%;

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 50% 50%;

    p {
      width: 80%;
    }
  }

  h2 {
    text-transform: lowercase;
  }

  p {
    margin-top: 0.5em;
    width: 65%;
    line-height: 1.5;
  }

  div:nth-of-type(3n + 1) {
    color: #fff;
  }

  div:first-of-type {
    background-color: #0a3542;

    background-image: url(${depression});
    background-repeat: no-repeat;
    background-size: 22%;

    background-position-y: 102%;
    background-position-x: 85%;

    p {
      width: 65%;

      @media (min-width: 600px) {
        width: 65%;
      }

      @media (min-width: 900px) {
        width: 70%;
      }
    }
  }

  div:nth-of-type(2) {
    background-color: #ebf5f4;
    align-items: flex-end;

    p {
      text-align: right;
      width: 80%;

      @media (min-width: 600px) {
        width: 65%;
      }

      @media (min-width: 900px) {
        width: 90%;
      }
    }

    background-image: url(${anxiety});
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 0 102%;
  }

  div:nth-of-type(3) {
    background-color: #b1dada;

    background-image: url(${relationship});
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: 100% 100%;

    p {
      width: 65%;

      @media (min-width: 900px) {
        width: 95%;
      }
    }
  }

  div:nth-of-type(4) {
    background-color: #3b3d7a;

    align-items: flex-end;

    p {
      text-align: right;

      @media (min-width: 600px) {
        width: 75%;
      }
    }

    h2 {
      text-align: right;
    }

    background-image: url(${professional});
    background-repeat: no-repeat;
    background-size: 22%;
    background-position: 8% 100%;
  }
`;
export const Disorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--vertical-half) var(--horizontal);

  min-height: 18rem;

  @media (min-width: 720px) {
    height: calc(18rem + 15vw);
  }

  @media (min-width: 900px) {
    &:nth-of-type(odd) {
      padding: var(--vertical-half) var(--horizontal-half) var(--vertical-half)
        var(--horizontal);
    }

    &:nth-of-type(even) {
      padding: var(--vertical-half) var(--horizontal) var(--vertical-half)
        var(--horizontal-half);
    }

    height: calc(12rem + 20vw);

    p {
      width: 85%;
    }
  }
`;
export const ProcessesContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: var(--vertical) var(--horizontal);

  div + div {
    margin-top: 4rem;
  }
`;
export const Process = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 1rem;
    text-align: center;
    width: 90%;
  }

  @media (min-width: 900px) {
    flex-direction: row;

    p {
      margin-left: 3rem;
      text-align: left;
    }
  }
`;
