import {
  FaAddressCard,
  FaClock,
  FaLocationArrow,
  FaMailBulk,
  FaPhone,
} from 'react-icons/fa';
import LocationMap from '../LocationMap';
import {
  Container,
  Title,
  Information,
  InfoItem,
  FooterBar,
  Spacer,
} from './styles';

export const Footer = () => {
  return (
    <>
      <Container>
        <Title>
          <h1>contato</h1>
          <FaAddressCard size={40} />
        </Title>

        <Information>
          <div className="map">
            <LocationMap
              mapContainerStyle={{
                height: `16rem`,
                width: '400px',
              }}
            />
          </div>
          <div className="group">
            <strong>Clinica de Psicologia Elaine Varotto</strong>
            <div className="wrapper">
              <InfoItem>
                <FaLocationArrow size={20} />
                <a href="https://www.google.com/maps/place/Av.+Juscelino+Kubitscheck,+670,+Mundo+Novo+-+MS,+79980-000/">
                  Av. Juscelino Kubitscheck, 670 <br />
                  Mundo Novo - MS
                </a>
              </InfoItem>
              <InfoItem>
                <FaClock size={20} />
                segunda à sexta <br />
                09h as 18hs
              </InfoItem>
            </div>
            <div className="wrapper">
              <InfoItem>
                <FaPhone size={20} />
                <div className="phone-group">
                  <a href="tel:5567992760909">(67) 99276-0909</a>
                  <a href="tel:5541998033701">(41) 99803-3701</a>
                </div>
              </InfoItem>
              <InfoItem>
                <FaMailBulk size={20} />
                <a
                  href="mailto:contato@psielainevarotto.com.br"
                  data-domain="psielainevarotto.com.br"
                >
                  contato@psielainevarotto.com.br{' '}
                </a>
              </InfoItem>
            </div>
          </div>
        </Information>
      </Container>
      <FooterBar>
        <p>
          ©2020 Elaine Cristina Varotto <br />
          CRP 08/31049
        </p>
        <p>
          desenvolvido por <br />{' '}
          <a href="https://linklist.bio/AndreGrossi">
            <strong>Andre Grossi</strong>
          </a>
        </p>
      </FooterBar>
      <Spacer />
    </>
  );
};
